
import { defineComponent } from "vue";
import { Form, useForm } from "vee-validate";

import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import { Modal } from "bootstrap";


interface TransportData {
  name: string;
  cuit: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {

    ElectronicSignature,
    Form,
  },
  data() {
    return {
      requireReason: true,
      requireExtra: true,
      signatureType: "update",
      leaderList: [],
      loading: false,
      userlist: [],
      criticalError: null,
      errors: {},
      supervisorList: [],
      transportList: {
        "0": "Transportista1",
        "1": "Transportista2",
      },
      entity: {
        id: "",
        electronicSignature: "",
        name: "",
             supervisorList: [],
        supervisor_id: "",
        leader_id: "",
        visitors: [],
              },

    };
  },
  methods: {
    triggerSignatureForm() {
      var modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },
    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.entity.electronicSignature = "";
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    getTransports() {
      ApiService.query("index/transports", {

      }).then((response) => {
        this.transportList = response.data;
      });
    },
    getLeaders() {
      ApiService.query("index/users", {
        params: {permission: ["lead_user"]},
      }).then((response) => {
        this.leaderList = response.data;
      });
    },
    getSupervisors() {
      ApiService.query("index/users", {
        params: { permission: "supervise_user" },
      }).then((response) => {
        this.supervisorList = response.data;
      });
    },
    submitFormWithSignature(signature) {
      this.entity.electronicSignature = signature;
      this.submitForm();
    },

    submitForm() {
      this.errors = {};

      if (this.entity.id) {
        this.signatureType = "update";
        ApiService.update("groups", this.entity.id.toString(), this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      } else {
        this.signatureType = "create";
        ApiService.post("groups", this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    this.getSupervisors();
    this.getLeaders();
this.getTransports()
    if (this.$route.params.id) {
      this.loading = true;
      ApiService.get("groups", this.$route.params.id.toString()).then(
        (response) => {
          this.entity.id = response.data.id;
          this.entity.name = response.data.name;
          this.entity.leader_id = response.data.leader_id.toString();
          this.entity.supervisor_id = response.data.supervisor_id.toString();
          this.loading = false;
          this.entity.visitors = response.data.visitors;
        }
      );

    }
  },
});
